export default class DoneListTableItem {
    //任务id
    taskId = "";
    //任务id
    processInstanceId = "";
    //审批类型
    processDefinitionName = "";
    //发起人
    startUser = {};
    //提交时间
    startTime = "";
    //任务到达时间
    taskCreatedTime = "";
    //当前节点
    currentActivityName = "";
    //审批状态
    businessStatus = "";
    //结束时间
    endTime = "";
    //整体耗时
    totalDuration = "";
    //节点耗时
    nodeDuration = "";
    //委派状态
    delegationState = "";
    //流程版本
    processDefinitionVersion = "";
}
