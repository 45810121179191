import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import DoneListTableItem from "@/entity/flowable/DoneListTableItem";
import ProcessWorkspace from "@/modules/flowable/workspace/process-workspace/process-workspace.vue";
import API from "@/api/flowable/workspace/done";

@Options({
    name: "app-flowable-workspace-done",
    components: {
        ProcessWorkspace,
    },
})
export default class Done extends BaseTableMixins<DoneListTableItem> implements Partial<BaseTableInterface<DoneListTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new DoneListTableItem();
        this.editModalItem = new DoneListTableItem();
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //处理流程
    public flowProcessing(item: any) {
        item.isProcess = false;
        (this.$refs.processView as any).show(item);
    }

    //流程提交
    public onFlowSubmit(e: any) {
        if ("RefreshTable" === e) this.getList(0);
    }
}
